import { Link } from 'gatsby'
import React from 'react'
import asfLogo from '../images/asf_logo.svg'
import gridgainLogo from '../images/GridGain_Logo_Alternate.svg'
import './footer.scss'

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="txtWrapper">
                    <nav>
                        <ul>
                            <li><a href="https://www.gridgain.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                            <li><a href="https://www.apache.org/foundation/policies/conduct" target="_blank" rel="noopener noreferrer">Code of conduct</a></li>
                            <li><Link to="/anti-harassment-policy">Anti-Harassment Policy</Link></li>
                        </ul>
                    </nav>
                    <p>Apache Ignite® and associated open source project names are trademarks of the Apache Software Foundation.</p>

                    <p>The Apache Software Foundation has no affiliation with and does not endorse, or review the materials provided at this event.</p>


                    <p className="organizers"><a href="https://www.gridgain.com/contact" target="_blank" rel="noopener noreferrer">Contact Organizers</a><br />
                        Copyright © GridGain {new Date().getFullYear()}</p>
                </div>
                <div className="company">
                    <div >
                        <p>Our Community Partner:</p>
                        <a href="https://www.apache.org"  target="_blank" rel="noopener noreferrer"><img src={asfLogo} alt="Apache Software Foundation logo" width={250} height={100} /></a>
                    </div>

                    <div >
                        <p style={{
                        marginTop: `2rem`
                    }}>Organized by:</p>
                    <a href="https://www.gridgain.com"  target="_blank" rel="noopener noreferrer"><img src={gridgainLogo} alt="GridGain logo" width={481} height={121} /></a>
                    </div>
                </div>

                
            </div>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/23545599.js"></script>
        </footer>
    )
}

export default Footer
