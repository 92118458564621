/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import "./variables.scss"
import "./layout.scss"
import Header from "./header"
import Footer from "./footer"


const Layout = ({ children, innerPage, title, attr="page" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={"layout-wrapper " + attr} id="layout-wrapper">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} innerPage={innerPage} />

        <main id="page-wrapper" className={`page-wrapper ${ innerPage ? 'innerPage' : '' }`}>
        { innerPage && title &&
          <div className="innerHeader"><div className="container"><h1>{title}</h1></div></div>
        }
        {children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  innerPage: PropTypes.bool,
  title: PropTypes.string
}

Layout.defaultProps = {
  innerPage: true,
  title: ''
}


export default Layout
