import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import burgericon from '../images/bars-solid.svg'
import crossicon from '../images/cross-solid.svg'
import './styles/MainMenu.scss'

export default class MainMenu extends Component {
  render() {
    return (
        <Menu 
        right
        customBurgerIcon={ <img src={burgericon} alt="Main Menu" /> }
        customCrossIcon={ <img src={crossicon} alt="Main Menu" /> }
        pageWrapId={"page-wrapper"}
        outerContainerId={"layout-wrapper"}
        width={ '330px' }
        >
        <ul>
            <li><Link activeClassName="active" to="/past-events">Past events</Link></li>
            <li><a href="https://ignite.apache.org" className="external" target="_blank" rel="noopener noreferrer">About Apache Ignite</a></li>
        </ul>

        </Menu>
    );
  }
}
