import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import './header.scss'

import Logo from "../components/Logo"
import Navigation from 'react-sticky-nav';
import MainMenu from '../components/MainMenu';

const Header = ({ innerPage }) => (
  
    <Navigation className={`headerWrapper ${ innerPage ? 'innerPage' : '' }`}>
      <div className="container">
          <Link
            to="/"
            className="logo"
          >
            <Logo />
          </Link>
        
        
          <div className={"main-menu"}><MainMenu /></div>
      </div>
    </Navigation>
  
)

Header.propTypes = {
  innerPage: PropTypes.bool
}

Header.defaultProps = {
  innerPage: true,
}

export default Header
