import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import logoSVG from '../images/ignite-summit-logo.svg'


const Logo = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }`);
    
    return (
        <img src={logoSVG} alt={data.site.siteMetadata.title} width={`101px`} height={`40px`}  />
    )
}

export default Logo